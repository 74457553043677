import { VueEditor } from 'vue2-editor'
import UserGuideServices from '../../../services/user-guide'
export default {
    components: {
        VueEditor
    },
    data() {
        return {
            item: {
                user_guide_id: null,
                content: null,
                userType: 'PROVIDER'
            },
            tabProviders: true,
            tabSuppliers: false,
            masterData: []
        }
    },
    created() {
        this.getUserGuide()
    },
    methods: {
        async publish() {
            if (this.tabSuppliers) {
                this.item.userType = 'SUPPLIER'
            } else {
                this.item.userType = 'PROVIDER'

            }
            var isValid = await this.$refs['observer'].validate()
            if (isValid && this.item.user_guide_id > 0) {
                this.updateUserGuides()
            } else if (isValid) {
                this.createUserGuides()
            }
        },
        createUserGuides() {
            UserGuideServices.createUserGuides(this.item).then(resp => {
                if (!resp.error) {
                    this._showToast('Added successfully!')
                }

            })
        },
        updateUserGuides() {
            UserGuideServices.updateUserGuides(this.item).then(resp => {
                if (!resp.error) {
                    this._showToast('Updated successfully!')
                }
            })
        },
        getUserGuide() {

            UserGuideServices.getUserGuide(this.item.userType).then(resp => {
                if (!resp.error &&   resp.data.d) {
                    this.item= resp.data.d
                }
            })
        },
        bindUserGuide(){
            setTimeout(() => {
                if (this.tabSuppliers) {
                    this.item.userType = 'SUPPLIER'
                } else {
                    this.item.userType = 'PROVIDER'
                }
                this.getUserGuide()
            }, 200)
        }
    }
}